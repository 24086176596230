import { Box, IconButton, Popover, type SxProps, TextField, Typography } from '@mui/material';
import { Button } from 'antd';
import { MessageSquareWarning } from 'lucide-react';
import { ChangeEvent, FC, MouseEvent, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { feedbackAPI } from 'src/api/feedback';
import { useAuth } from 'src/hooks/use-auth';
import { usePathname } from 'src/hooks/use-pathname';
import { tokens } from 'src/locales/tokens';

interface FeedbackButtonProps {
  style?: React.CSSProperties;
  iconSX?: SxProps;
}
const FeedbackButton: FC<FeedbackButtonProps> = (props) => {
  const { style = {}, iconSX = {} } = props;
  const { user } = useAuth();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [text, setText] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const pathname = usePathname();
  const { t } = useTranslation();
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'text') {
      setText(event.target.value);
    } else if (event.target.name === 'email') {
      setEmail(event.target.value);
    }
  };
  const handleSubmitFeedback = async () => {
    try {
      setIsLoading(true);
      await feedbackAPI.sendFeedback({ pathname, email, comment: text });
      toast.success(t(tokens.feedback.submitSuccess));
      setText('');
      setEmail('');
      handleClose();
    } catch (error) {
      toast.error(t(tokens.feedback.submitFailure));
    } finally {
      setIsLoading(false);
    }
  };
  const open = Boolean(anchorEl);
  const id = open ? 'feedback-popover' : undefined;

  return (
    <>
      <IconButton
        sx={{
          bgcolor: '#E858FF',
          '&:hover': {
            bgcolor: '#E858FF',
            opacity: 0.7,
          },
          position: 'absolute',
          bottom: 50,
          right: 10,
          zIndex: 10,
        }}
        aria-describedby={id}
        style={{
          ...style,
        }}
        onClick={handleClick}
      >
        <MessageSquareWarning
          color="white"
          size={18}
        />
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box p={2}>
          <Typography
            variant="subtitle1"
            sx={{ py: 2 }}
          >
            {t(tokens.feedback.title)}
          </Typography>
          <TextField
            name="text"
            fullWidth
            hiddenLabel
            variant="filled"
            size="small"
            multiline
            rows={5}
            value={text}
            onChange={handleOnChange}
          />
          {!user?.id && (
            <TextField
              name="email"
              sx={{ my: 2 }}
              fullWidth
              label="Email (optional)"
              value={email}
              onChange={handleOnChange}
              variant="outlined"
              size="small"
            />
          )}
          <Box
            display="flex"
            justifyContent="flex-end"
          >
            <Button
              style={{ marginTop: '5px' }}
              onClick={handleSubmitFeedback}
              disabled={text.trim()?.length < 2 || isLoading}
            >
              {isLoading ? `${t(tokens.common.uploading)}...` : t(tokens.common.submit)}
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default FeedbackButton;
