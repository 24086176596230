import axios, { type AxiosInstance } from 'axios';
import { STORAGE_KEY } from 'src/contexts/auth/auth-provider';
import { checkForExpiredToken } from 'src/utils/jwt';

class AuthAxios {
  protected axiosInstance: AxiosInstance;

  constructor(params: { baseUrl: string }) {
    this.axiosInstance = axios.create({
      baseURL: `${params.baseUrl}/v1`,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    this.injectHeaders(this.axiosInstance);
  }

  private checkExpiredToken(token: string) {
    checkForExpiredToken(token);
  }

  private injectHeaders(axios: AxiosInstance) {
    axios.interceptors.request.use(
      (config) => {
        const token = sessionStorage.getItem(STORAGE_KEY);
        if (token) {
          this.checkExpiredToken(token);
          config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }
}

export default AuthAxios;
