import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { SMBBusiness } from 'src/types/organization';

type SetSMBListAction = PayloadAction<SMBBusiness[]>;
type SetMySMBAction = PayloadAction<SMBBusiness>;
interface SMBState {
  mySMB: SMBBusiness | null;
  
  // Deprecated
  smbs: SMBBusiness[];
}

const initialState: SMBState = {
  mySMB: null,
  smbs: [],
};

const reducers = {
  setMySMB(state: SMBState, action: SetMySMBAction): void {
    state.mySMB = action.payload;
  },
  setSMBList(state: SMBState, action: SetSMBListAction): void {
    state.smbs = action.payload;
  },
};

export const slice = createSlice({
  name: 'smb',
  initialState,
  reducers,
});

export const { reducer } = slice;
