import { tokens } from '../tokens';

export const en = {
  [tokens.prefix.prefixCompany]: 'compan',
  [tokens.suffix.suffixCompanyPlural]: 'ies',
  [tokens.suffix.suffixCompanySingular]: 'y',

  [tokens.common.languageChanged]: 'Language changed',
  [tokens.common.termsConditions]: 'Terms & Privacy Policy',
  [tokens.common.close]: 'Close',
  [tokens.common.save]: 'Save',
  [tokens.common.companyName]: 'Company Name',
  [tokens.common.address]: 'Address',
  [tokens.common.website]: 'Website',
  [tokens.common.phoneNumber]: 'Phone Number',
  [tokens.common.email]: 'Email',
  [tokens.common.keywords]: 'Keywords',
  [tokens.common.companyStatement]: 'Company Statement',
  [tokens.common.edit]: 'Edit',
  [tokens.common.name]: 'Name',
  [tokens.common.actions]: 'Actions',
  [tokens.common.delete]: 'Delete',
  [tokens.common.search]: 'Search',
  [tokens.common.details]: 'Details',
  [tokens.common.sector]: 'Sector',
  [tokens.common.sendInterviews]: 'Send Interviews',
  [tokens.common.stateCountry]: 'State/Country',
  [tokens.common.typeHere]: 'Type here',
  [tokens.common.numberOfBusinesses]: 'Number of Businesses',
  [tokens.common.uploading]: 'Uploading',
  [tokens.common.cancel]: 'Cancel',
  [tokens.common.submit]: 'Submit',
  [tokens.common.submitting]: 'Submitting',

  [tokens.banner.enrichmentTitle]: 'Enrichment Status',
  [tokens.banner.enrichmentBody]: '{{percentage}}% of the enrichment is completed',
  [tokens.banner.enrichmentError]:
    'There was an error while fetching the enrichment percentage. Please, refresh the page to try again',
  [tokens.banner.enrichmentLoading]: 'Fetching information...',
  [tokens.banner.enrichmentUpdatedAt]: 'Last updated information at: {{updatedAt}}',

  [tokens.nav.dashboard]: 'Dashboard',
  [tokens.nav.error]: 'Error',
  [tokens.nav.login]: 'Login',
  [tokens.nav.register]: 'Register',

  [tokens.tabs.members]: 'Members',
  [tokens.tabs.events]: 'Events',

  [tokens.dashboard.tiles.totalManufacturers]: 'Total Manufacturers',
  [tokens.dashboard.tiles.totalPaidUsers]: 'Paid Users',
  [tokens.dashboard.tiles.totalMeetingsArranged]: 'Meetings Arranged',
  [tokens.dashboard.tiles.commoditiesNeeded]: 'Commodities Needed',

  [tokens.dashboard.tiles.totalMembers]: 'Total Members',
  [tokens.dashboard.tiles.totalThreshold]: 'Suppliers Over Risk Threshold',
  [tokens.dashboard.tiles.membersEnriched]: 'Members Enriched',
  [tokens.dashboard.tiles.overallSupplyChain]: 'Overall Supply Chain Risk Year-to-Date Change',
  [tokens.dashboard.tiles.sectorDistribution]: 'Sector Distribution',
  [tokens.dashboard.tiles.topSectors]: 'Top Sectors',
  [tokens.dashboard.tiles.averageCompanySize]: 'Average Company Size',
  [tokens.dashboard.tiles.demoDistribution]: 'Impacted Suppliers in the Middle East',
  [tokens.dashboard.tiles.onlinePresence]: 'Online Presence Claimed',
  [tokens.dashboard.tiles.totalMembersBackTile]: 'You have a total of **{{totalMembers}}** members',
  [tokens.dashboard.tiles.successfullyEnriched]:
    'You have **{{enriched}}** compan{{plural}} with enrichment complete',
  [tokens.dashboard.tiles.noSuccessfullyEnriched]:
    'You do not have any companies with enrichment complete',
  [tokens.dashboard.tiles.failedEnriched]:
    '**{{failed}}** compan{{plural}} where enrichment failed',
  [tokens.dashboard.tiles.awaitingEnrichment]:
    '**{{awaitingEnrichment}}** compan{{plural}} awaiting enrichment',
  [tokens.dashboard.tiles.progressEnrichment]:
    '**{{inProgress}}** compan{{plural}} are currently being enriched',
  [tokens.dashboard.tiles.enrichmentNotPossible]:
    '**{{enrichmentNotPossible}}** compan{{plural}} where enrichment is not possible',
  [tokens.dashboard.tiles.noCurrentEnrichment]:
    'And there are **no companies** currently in any enrichment process',
  [tokens.dashboard.tiles.sectorDistributionTileBackHeading]:
    "Here's a breakdown of the multiple **sectors** your businesses are part of",
  [tokens.dashboard.tiles.averageCompanyTileBackHeading]:
    'This is a list of all the category sizes your companies belong to:',
  [tokens.dashboard.tiles.presenceNotClaimed]: 'There are no businesses records to display.',
  [tokens.dashboard.tiles.presenceAllClaimed]:
    'All **{{claimedCount}}** businesses have already claimed their site.',
  [tokens.dashboard.tiles.presenceClaimedCount0]:
    'There are **{{notClaimedCount}}** businesses that **have not** claimed their online presence, and **no businesses have already claimed their site**.',
  [tokens.dashboard.tiles.presenceClaimedCount]:
    'There are **{{notClaimedCount}}** businesses that have not claimed their online presence and **{{claimedCount}}** businesses who have already claimed their site.',

  [tokens.welcome.title]: 'Welcome to LevelPlane!',
  [tokens.welcome.subtitle]:
    'You are about to be a part of a cutting-edge AI service built to take care of all your business needs',
  [tokens.welcome.prompt1]:
    'From connecting you to small businesses around the world to even helping to facilitate deals with them, LevelPlane serves as your comprehensive solution for all your business needs. Our advanced AI model, driven by state-of-the-art technology, goes beyond conventional networking. It intelligently links you with the most suitable business partners across all regions, offering unparalleled opportunities for growth and collaboration. A custom chatbot we built exclusively for you will not only establish connections but also act as a virtual representative, proficiently communicating on your behalf.',
  [tokens.welcome.prompt2]: 'Begin by taking the interview with our AI Assistant.',
  [tokens.welcome.changes]:
    'Please make any changes necessary to your information to finish creating your account.',
  [tokens.welcome.keywordBlur]: 'Please add 10 words to describe your business',
  [tokens.welcome.passwordPrompt]: "Let's make this easier for next time.",
  [tokens.welcome.passwordMessage]: 'Hit save to get started',
  [tokens.welcome.interview.pressBarSpace]:
    'Press the space bar to talk, and release it when you finish talking.',
  [tokens.welcome.interview.pressButton]:
    'Hold the button while speaking and release it after finishing.',
  [tokens.welcome.interview.startInterview]: 'Start the interview',
  [tokens.welcome.interview.resumeInterview]: 'Resume the interview',

  [tokens.chat
    .openingMessage]: `I'm here to help you find suppliers and service providers in Central Mexico including the states of Aguascalientes, Guanajuato, Jalisco, Querétaro and San Luis Potosi. Let's find the perfect match for your needs, quickly and easily! Just tell me what you're looking for...`,
  [tokens.chat
    .openingMessageDemo3]: `I’m here to help you to setup and expand your business in Dubai and find the perfect match for your needs. Just tell me what you're looking for…`,
  [tokens.chat.hi]: 'Hello!',
  [tokens.chat.leaveAMessage]: 'Leave a message',
  [tokens.chat.conversationStarterPrimary1]: 'Help me find',
  [tokens.chat.conversationStarterSecondary1]: 'a supplier for **construction concrete**.',
  [tokens.chat.conversationStarterPrimary2]: 'Show me',
  [tokens.chat.conversationStarterSecondary2]:
    'a reliable source for **high-quality glass materials**.',
  [tokens.chat.conversationStarterPrimary3]: 'Look for',
  [tokens.chat.conversationStarterSecondary3]: 'vendor of **medical equipment and supplies**.',
  [tokens.chat.conversationStarterPrimary4]: 'Search for',
  [tokens.chat.conversationStarterSecondary4]:
    'a reputable provider of **agricultural fertilizers**.',

  [tokens.chat.chatLoaderMessage1]: 'Analyzing your request',
  [tokens.chat.chatLoaderMessage2]: "I'm still gathering information for you",
  [tokens.chat.chatLoaderMessage3]: 'Working to find the best answer',
  [tokens.chat.chatLoaderMessage4]: 'Almost there! Finalizing details',

  [tokens.chambers.memberList]: 'Member List',
  [tokens.chambers.sendInterview]: 'Send Interview',

  [tokens.errors.dashboardAPI]: 'An error occurred when trying to fetch the data for the dashboard',

  [tokens.feedback.sendFeedback]: 'Send Feedback',
  [tokens.feedback.title]: 'Please, type your feedback below',
  [tokens.feedback.placeholder]: 'Type your feedback here',
  [tokens.feedback.submitSuccess]: 'Thank you for your feedback!',
  [tokens.feedback.submitFailure]: 'There was an error sending your feedback. Please. try again',

  [tokens.companySizeMap.a]: 'Self-employed',
  [tokens.companySizeMap.b]: '1 - 10 employees',
  [tokens.companySizeMap.c]: '11 - 50 employees',
  [tokens.companySizeMap.d]: '51 - 200 employees',
  [tokens.companySizeMap.e]: '201 - 500 employees',
  [tokens.companySizeMap.f]: '501 - 1000 employees',
  [tokens.companySizeMap.g]: '1001 - 5000 employees',
  [tokens.companySizeMap.h]: '5001 - 10,000 employees',
  [tokens.companySizeMap.i]: '+10,000 employees',
};
