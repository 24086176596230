import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { FC, ReactNode } from 'react';
import { Stack } from '@mui/material';

import { PanelRightClose, Settings, Home } from 'lucide-react';
import { Tooltip } from '@lobehub/ui';
import { useLocation, useNavigate } from 'react-router';
import { paths } from 'src/paths';
import { useDrawerActions, useDrawerState } from 'src/contexts/drawer/hooks';
import LogoutIcon from './logout-icon';
import { BusinessAppBar, BusinessDrawer, BusinessMain } from 'src/layouts/common/styled-components';
import { useSelector } from 'src/store';
import FeedbackButton from 'src/layouts/dashboard/horizontal-layout/feedback-button';
import { Button, Typography } from 'antd';

interface MiniDrawerProps {
  children: ReactNode;
}

const MiniDrawer: FC<MiniDrawerProps> = (props) => {
  const { children } = props;
  const mySMB = useSelector((state) => state.smb.mySMB);
  const { setDrawerOpen } = useDrawerActions();
  const { open, drawerWidth, drawerComponent } = useDrawerState();

  const navigate = useNavigate();
  const location = useLocation();
  const handleDrawerToggle = () => {
    setDrawerOpen(!open);
  };

  const handleNavigation = (url: string) => {
    navigate(url);
  };
  const handleHomeNavigate = () => {
    navigate(paths.business.insights);
  };

  return (
    <>
      <Stack
        direction="row"
        sx={{ flex: 1, maxWidth: '100%' }}
      >
        <Box sx={{ display: 'flex', position: 'relative', flex: 1, maxWidth: '100%' }}>
          {drawerWidth > 0 && (
            <BusinessDrawer
              open
              drawerWidth={drawerWidth}
            >
              <div
                style={{
                  width: drawerWidth,
                  overflowX: 'hidden',
                  position: 'relative',
                  backgroundColor: '#F9F9F9',
                }}
              >
                {drawerComponent}
              </div>
            </BusinessDrawer>
          )}
          <BusinessMain
            open={open}
            drawerWidth={drawerWidth}
          >
            {children}
          </BusinessMain>
        </Box>
      </Stack>
      <FeedbackButton />
    </>
  );
};

export default MiniDrawer;
