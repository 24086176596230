import { STORAGE_KEY } from "src/contexts/auth/auth-provider";
import { paths } from "src/paths";
import { User } from "src/types/user";

/* eslint-disable no-bitwise */
export const JWT_SECRET = 'levelplane-top-secret-key';
export const JWT_EXPIRES_IN = 3600 * 24 * 2; // 2 days

export const sign = (
  payload: Record<string, any>,
  privateKey: string,
  header: Record<string, any>
) => {
  const now = new Date();
  header.expiresIn = new Date(now.getTime() + header.expiresIn);
  const encodedHeader = btoa(JSON.stringify(header));
  const encodedPayload = btoa(JSON.stringify(payload));
  const signature = btoa(
    Array.from(encodedPayload)
      .map((item, key) =>
        String.fromCharCode(item.charCodeAt(0) ^ privateKey[key % privateKey.length].charCodeAt(0))
      )
      .join('')
  );

  return `${encodedHeader}.${encodedPayload}.${signature}`;
};

export const decode = (token: string): any => {
  const [encodedHeader, encodedPayload, signature] = token.split('.');
  const header = JSON.parse(atob(encodedHeader));
  const payload = JSON.parse(atob(encodedPayload));
  const now = new Date();

  if (now < header.expiresIn) {
    throw new Error('Expired token');
  }

  return payload;
};

export const verify = (token: string, privateKey: string): Record<string, any> => {
  const [encodedHeader, encodedPayload, signature] = token.split('.');
  const header = JSON.parse(atob(encodedHeader));
  const payload = JSON.parse(atob(encodedPayload));
  const now = new Date();

  if (now < header.expiresIn) {
    throw new Error('Expired token');
  }

  return payload;
};

export function checkForExpiredToken(token: string) {
  const user = decode(token) as User;
  const currentTime = Math.floor(Date.now() / 1000); // To seconds
  
  // Subtract 5 minutes to avoid checking the token at the last second
  // const fiveMinutesInSeconds = 5 * 60;

  if (currentTime > user.exp) {
    console.log('Token will expire soon. Logging out.');
    sessionStorage.removeItem(STORAGE_KEY);
    window.location.href = paths.auth.login;
  }
}