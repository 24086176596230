import { AppThunk } from 'src/store';
import { slice } from 'src/slices/onboarding';
import { uploadSMBAPI } from 'src/api/upload-smbs';
import { smbApi } from 'src/api/smb';

const fetchInteractionStyle = (): AppThunk => async (dispatch) => {
  try {
    const interactionStyle = await uploadSMBAPI.checkInteractionStyle();
    dispatch(slice.actions.setInteractionStyle(interactionStyle));
  } catch (error) {
    console.error('Failed to set interaction style:', error);
  }
};

const fetchBusinessProfile = (smbId: string): AppThunk => async (dispatch) => {
  try {
    const data = await smbApi.getSMBDetails(smbId);
    dispatch(slice.actions.setBusinessProfile(data));
  } catch (error) {
    console.error('Failed to set interaction style:', error);
  }
};

const setHasMembers = (orgId: string): AppThunk => async (dispatch) => {
  try {
    const hasMembers = await uploadSMBAPI.hasMembers(orgId);
    dispatch(slice.actions.setHasMembers(hasMembers));
  } catch (error) {
    dispatch(slice.actions.setHasMembers(false));
    console.error('Failed to set hasMembers flag:', error);
  }
};



export const thunks = {
  fetchInteractionStyle,
  setHasMembers,
  fetchBusinessProfile,
};