import { lazy } from 'react';
import { Outlet, type RouteObject } from 'react-router';

const InternalDashPage = lazy(() => import('src/pages/internal-dash'));

export const internalRoutes: RouteObject[] = [
  {
    path: 'internal',
    //leaving this here in case we want to add a guard later
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <InternalDashPage />,
      },
    ],
  },
];
