import { useEffect, type FC, type ReactNode } from 'react';

import { HorizontalLayout } from './horizontal-layout';
import { smbApi } from 'src/api/smb';
import { useAuth } from 'src/hooks/use-auth';
import { useDispatch } from 'src/store';
import { slice } from 'src/slices/smb';

interface LayoutProps {
  children?: ReactNode;
}

const useMySMB = async () => {
  const { user } = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchSMB = async () => {
      try {
        const smb = await smbApi.getSMBDetails(user?.smb_id as string);
        dispatch(slice.actions.setMySMB(smb));
      } catch (error) {
        console.error(error);
      }
    };

    fetchSMB();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.smb_id]);
};

export const Layout: FC<LayoutProps> = (props) => {
  useMySMB();
  return <HorizontalLayout {...props} />;
};
