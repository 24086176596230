import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import { FC, ReactNode } from 'react';
import { Stack } from '@mui/material';
import { MessageCircle, Compass, Settings } from 'lucide-react';
import { Tooltip } from '@lobehub/ui';
import { useLocation, useNavigate } from 'react-router';
import { paths } from 'src/paths';
import { useDrawerActions, useDrawerState } from 'src/contexts/drawer/hooks';
import { leftBarDrawer, Main, Drawer } from 'src/layouts/common/styled-components';
import FeedbackButton from 'src/layouts/dashboard/horizontal-layout/feedback-button';

interface MiniDrawerProps {
  children: ReactNode;
}

const MiniDrawer: FC<MiniDrawerProps> = (props) => {
  const { children } = props;
  const theme = useTheme();
  const { setDrawerOpen } = useDrawerActions();
  const { open, drawerWidth, drawerComponent } = useDrawerState();
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigation = (url: string) => {
    setDrawerOpen(url !== paths.workspace.insights);
    navigate(url);
  };

  return (
    <>
      <Stack
        direction="row"
        sx={{ flex: 1, maxWidth: '100%' }}
      >
        <Box sx={{ display: 'flex', position: 'relative', flex: 1, maxWidth: '100%' }}>
          <Drawer
            open
            drawerWidth={drawerWidth}
          >
            <Box
              sx={{
                width: '70px',
                zIndex: 9999,
                borderRight: () => `1px solid ${theme.palette.grey[300]}`,
                bgcolor: '#f7f7f7',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <List>
                <ListItem>
                  <Tooltip
                    title="Insights"
                    arrow
                    zIndex={9999}
                    placement="right"
                  >
                    <IconButton
                      sx={{
                        borderRadius: 1,
                        bgcolor: (theme) =>
                          location.pathname === paths.workspace.insights
                            ? theme.palette.grey[300]
                            : 'inherit',
                      }}
                      onClick={() => handleNavigation(paths.workspace.insights)}
                    >
                      <Compass />
                    </IconButton>
                  </Tooltip>
                </ListItem>
                <ListItem>
                  <Tooltip
                    title="Chats"
                    arrow
                    zIndex={9999}
                    placement="right"
                  >
                    <IconButton
                      sx={{
                        bgcolor: (theme) =>
                          location.pathname === paths.workspace.chat
                            ? theme.palette.grey[300]
                            : 'inherit',
                        borderRadius: 1,
                      }}
                      onClick={() => handleNavigation(paths.workspace.chat)}
                    >
                      <MessageCircle />
                    </IconButton>
                  </Tooltip>
                </ListItem>
                <ListItem>
                  <Tooltip
                    title="Memory"
                    arrow
                    zIndex={9999}
                    placement="right"
                  >
                    <IconButton
                      sx={{
                        bgcolor: (theme) =>
                          location.pathname.startsWith(paths.workspace.memory.index)
                            ? theme.palette.grey[300]
                            : 'inherit',
                        borderRadius: 1,
                      }}
                      onClick={() => handleNavigation(paths.workspace.memory.index)}
                    >
                      <img
                        src="/assets/icons/artificial-brain-2.png"
                        width={27}
                      />
                    </IconButton>
                  </Tooltip>
                </ListItem>
              </List>
              <IconButton
                sx={{
                  marginBottom: '1rem',
                  bgcolor: (theme) =>
                    location.pathname === paths.workspace.settings
                      ? theme.palette.grey[300]
                      : 'inherit',
                }}
                onClick={() => handleNavigation(paths.workspace.settings)}
              >
                <Tooltip
                  title="Settings"
                  arrow
                  zIndex={9999}
                  placement="bottom"
                >
                  <Settings />
                </Tooltip>
              </IconButton>
            </Box>
            <div style={{ width: drawerWidth, overflowX: 'hidden', position: 'relative' }}>
              {drawerComponent}
            </div>
          </Drawer>
          <Main
            open={open}
            drawerWidth={drawerWidth + leftBarDrawer}
          >
            {children}
          </Main>
        </Box>
      </Stack>
      <FeedbackButton />
    </>
  );
};

export default MiniDrawer;
