import { lazy } from 'react';
import type { RouteObject } from 'react-router';
import { Outlet } from 'react-router-dom';
import { GuestGuard } from 'src/guards/guest-guard';

const LoginPage = lazy(() => import('src/pages/auth/login'));
const ForgotPasswordPage = lazy(() => import('src/pages/auth/forgot-password'));
const ResetPasswordPage = lazy(() => import('src/pages/auth/reset-password'));
const SetPasswordPage = lazy(() => import('src/pages/auth/set-password'));
const V2LoginPage = lazy(() => import('src/pages/auth/v2-login'));
const InternalPage = lazy(() => import('src/pages/auth/internal-login'));
const RegisterPage = lazy(() => import('src/pages/auth/registration-exp'));

export const authRoutes: RouteObject[] = [
  {
    path: 'auth',
    element: (
      <GuestGuard>
        <Outlet />
      </GuestGuard>
    ),
    children: [
      {
        //deprecated but leaving here for now in case we need to switch back for any reason
        path: 'v1login',
        element: <LoginPage />,
      },
      {
        path: 'login',
        element: <V2LoginPage />
      },
      {
        path: 'signup',
        element: <RegisterPage />
      },
      {
        path: 'internal-login',
        element: <InternalPage />
      },
      {
        path: 'login-cad-index',
        element: <LoginPage isCadelecIndex />,
      },
      {
        path: 'forgot-password',
        element: <ForgotPasswordPage />,
      },
      {
        path: 'reset-password',
        element: <ResetPasswordPage />,
      },
      {
        path: 'set-password',
        element: <SetPasswordPage />,
      },
    ],
  },
];
